// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

const Trix = require("trix")
require("@rails/actiontext")

Trix.config.textAttributes.cloze = {
  tagName: 'cloze',
  inheritable: true
}

Trix.config.blockAttributes.answer = {
  tagName: 'answer',
  nestable: true
}


addEventListener("trix-initialize", function(event) {
  var clozeHTML = '<button class="trix-button" type="button" data-trix-attribute="cloze" title="Cloze">{...}</button>'
  var answerHTML = `<button type="button" class="trix-button" data-trix-attribute="answer" title="Answer" tabindex="-1">&nbsp;━&nbsp</button>`
  var buttonGroup = `<span class="trix-button-group trix-button-group--enki-tools" data-trix-button-group="enki-tools"></span>`

  event.target.toolbarElement.
  querySelector(".trix-button-group.trix-button-group--block-tools").
  insertAdjacentHTML("afterend", buttonGroup);

  event.target.toolbarElement.
  querySelector(".trix-button-group.trix-button-group--enki-tools").
  insertAdjacentHTML("beforeend", clozeHTML)

  event.target.toolbarElement.
  querySelector(".trix-button-group.trix-button-group--enki-tools").
  insertAdjacentHTML("beforeend", answerHTML)
})